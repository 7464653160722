:root {
  --reach-combobox: 1;
}

[data-reach-combobox-popover] {
  border: solid 1px hsla(0, 0%, 0%, 0.25);
  background: hsla(0, 100%, 100%, 0.99);
  font-size: 85%;
}

[data-reach-combobox-list] {
  list-style: none;
  margin: 0;
  padding: 0;
  user-select: none;
}

[data-reach-combobox-option] {
  cursor: pointer;
  margin: 0;
  padding: 0.25rem 0.5rem;
}

[data-reach-combobox-option][aria-selected="true"] {
  background: hsl(211, 10%, 95%);
}

[data-reach-combobox-option]:hover {
  background: hsl(211, 10%, 92%);
}

[data-reach-combobox-option][aria-selected="true"]:hover {
  background: hsl(211, 10%, 90%);
}

[data-suggested-value] {
  font-weight: bold;
}
